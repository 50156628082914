(function() {
  jQuery(function() {
    return $(function() {
      $(".js-expandable").on('click', 'input.chk', function(e) {
        if ($(this).is(":checked")) {
          return $(this).closest(".js-expandable").removeClass("-off");
        } else {
          return $(this).closest(".js-expandable").addClass("-off");
        }
      });
      $(".js-slot").on('click', function(e) {
        $(this).closest("ul").find(".time").hide();
        return $(this).closest("li").find(".time").show();
      });
      return $(".js-available-dates").find("input").change(function() {
        if ($(".js-available-dates").find("input:checked").length > 0) {
          return $(".js-available-no").hide();
        } else {
          return $(".js-available-no").show();
        }
      });
    });
  });

}).call(this);